import React from 'react';
import Layout from './layout';
import SEO from './seo';
import PageHeading from './PageHeading';

import styled, { css } from 'styled-components';
import theme from './../data/theme';

import QuoteForm from './QuoteForm';
import { whenTablet } from './../utils/responsive';

import { SectionHeading, Note } from './textComponents';

const MainWrapper = styled.div`
  width: ${ theme.contentWidth };
  max-width: ${ theme.maxContentWidth };
  margin: 5rem auto;
  display: flex;
  flex-wrap: wrap;
`;

const LeftContent = styled.div`
  flex: 1.6 1 0;
  padding: 2.5rem 0rem;
  margin-right: 2.5rem;

  ${ whenTablet(css`
    padding: 2.5rem 0rem;
    margin-right: 4rem;
  `) }
`;

const RightPanel = styled.div`
  flex: 1 1 0;
  padding: 2.5rem 0rem;

  ${ whenTablet(css`
    padding: 2.5rem 0rem;
  `) }
`;

const QuoteWrapper = styled.div`
  max-width: 40rem;
  min-width: 30rem;
  margin: 0 auto;
  padding: 1.5rem;
  background-color: ${ theme.colors.lightcloud };

  ${ whenTablet(css`
    padding: 2.5rem;
  `) }
`;

const QuoteSection = () => (
  <QuoteWrapper>
    <SectionHeading>Get A Quote</SectionHeading>
    <Note>Just fill this out, and we will contact you to discuss your project.</Note>
    <QuoteForm />
  </QuoteWrapper>
);

export default ({ page, heading, children }) => (
  <Layout>
    <SEO title={page.title} keywords={page.keywords} />
    <PageHeading title={heading.title} img={heading.img} alt={heading.alt}/>
    <MainWrapper>
      <LeftContent>
        {children}
      </LeftContent>
      <RightPanel>
        <QuoteSection />
      </RightPanel>
    </MainWrapper>
  </Layout>
);

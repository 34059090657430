import React from 'react';

import ServicesPage from './../../components/ServicesPage';
import { TextSection, Declaration, DL, DT, DD } from '../../components/textComponents';

import resLandscapingPhoto from '../../../static/assets/flowerbed_chopped.png';

const keywords = [
  'VLP',
  'Landscaping',
  'Landscaper',
  'Matt Vojticek',
  'Northeast Ohio',
];

const page = {
  title: 'Residential Landscaping',
  keywords: keywords,
  description: 'VLP Landscaping performs landscaping and lawn maintenance for local homeowners.  We handle everything from planting and growing, to trimming and cleanup.',
};

const heading = {
  title: 'Residential Landscaping',
  img: resLandscapingPhoto,
  alt: 'Flowerbed with mulch and precise edging',
};

export default () => (
  <ServicesPage page={page} heading={heading}>
    <TextSection>
      <Declaration>
        VLP Landscaping provides the following services to residential customers:
      </Declaration>
      <DL>
        <DT>Mowing</DT>
        <DD>With this landscaping maintenance service, we will mow your grass as needed seasonally. We will also trim and edge any sidewalks and obstacles and blow clippings from hard surfaces.</DD>

        <DT>Bed Edging</DT>
        <DD>We will cut out and edge all the exsisting beds with a nice sharp, deep edge.</DD>

        <DT>Weed Care</DT>
        <DD>As part of our landscaping maintenance services, we will help minimize and control weeds in your landscaping by hand weeding and applying weed control treatments to your flower and plant beds.</DD>

        <DT>Mulching</DT>
        <DD>With our mulching services, we will spread mulch in all of your beds and around your trees as needed for aesthetic appeal and weed control.</DD>

        <DT>Trimming and Pruning</DT>
        <DD>With this service, we will trim and prune any shrubs, plants, and ornamental grasses to ensure good shape and plant health.</DD>

        <DT>Spring Cleanup</DT>
        <DD>We will clean all beds and turf free of any debris and cut back any dead annuals.</DD>

        <DT>Leaf Removal</DT>
        <DD>We will remove fallen leaves from both your landscaping beds and turf areas.</DD>

        <DT>Spring and Fall Annual Planting</DT>
        <DD>We can plant a variety of annual flowers and plants throughout your landscape to provide color and design in any season.</DD>

        <DT>Removal of Annuals</DT>
        <DD>This landscaping maintenance service includes removing any dead annuals after the growing season has ended.</DD>
      </DL>
    </TextSection>
  </ServicesPage>
);
